import { Link } from "gatsby"
import React from "react"

import Layout from "../../../components/layout-static"
import Seo from "../../../components/seo"

const Page = () => {
  const metadata = {
    title: "BLOG",
    heroH1: "BLOG",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>
              Navigating the Legal Landscape: A Guide on How to File a Wrongful Death Lawsuit
            </h2>
            <p className="mb-8">
              By Frank Bartlett | Published December 19, 2023
            </p>
            <p className="mb-8">
              Losing a loved one is an incredibly challenging experience, and when that loss is due to someone else's negligence or wrongful actions, the pain can be even more profound. In such cases, pursuing justice through a wrongful death lawsuit may be a viable option. The team at Bartlett & Grippe aims to provide a comprehensive overview of the steps involved in filing a wrongful death lawsuit....{" "}
              <Link to="/a-guide-on-how-to-file-a-wrongful-death-lawsuit">
                (Read more)
              </Link>
            </p>
          </div>

          <div className="mb-16">
            <h2>
              Hit-and-Run Accidents: How to Pursue Legal Action Without the
              At-Fault Driver
            </h2>
            <p className="mb-8">
              By Frank Bartlett | Published November 29, 2023
            </p>
            <p className="mb-8">
              In the chaos of a car accident, emotions run high and decisions
              can often be hastily made. Unfortunately, hit-and-run accidents
              add an extra layer of complexity to an already challenging
              situation. If the at-fault driver flees the scene, victims are
              left grappling with both physical injuries and the frustration of
              not having a responsible party to hold accountable....{" "}
              <Link to="/how-to-pursue-legal-action-without-the-at-fault-driver">
                (Read more)
              </Link>
            </p>
          </div>

          <div className="mb-16">
            <h2>What is wrongful death and who can make a legal claim?</h2>
            <p className="mb-8">By Frank Bartlett | Published April 28, 2022</p>
            <p className="mb-8">
              Connecticut residents who face the shock and loss of a loved one
              dying in an accident will wonder what the future holds. As they
              come to grips with what happened, the gravity of the situation
              will become increasingly real and they need to assess how they
              will move forward. While it is hard to think about, there will be
              financial consequences for an unexpected death. Considering filing
              a wrongful death lawsuit is an option. With wrongful death claims,
              however, it is essential to be fully aware as to what state law
              says as to who can file and other key legal factors....{" "}
              <Link to="/what-is-wrongful-death-and-who-can-make-a-legal-claim">
                (Read more)
              </Link>
            </p>
          </div>
          <div className="mb-16">
            <h2>What Damages Can Be Sought In A Wrongful Death Claim?</h2>
            <p className="mb-8">By Frank Bartlett | Published March 22, 2022</p>
            <p className="mb-8">
              Losing a loved one is always hard, but timing can make some losses
              harder than others. Sometimes we lose a loved one after a
              protracted illness or due to old age. In these cases, we may have
              had time to prepare ourselves, both emotionally and financially.
              When death comes more suddenly, such as in a car crash or other
              accident, the shock can be much worse, and the loss can leave
              family members in severe emotional and financial distress...{" "}
              <Link to="/what-damages-can-be-sought-in-a-wrongful-death-claim">
                (Read more)
              </Link>
            </p>
          </div>
          <div className="mb-16">
            <h2>HARTFORD TEENAGER DIES FROM CRASH AFTER POLICE CHASE</h2>
            <p className="mb-8">
              By Frank Bartlett | Published August 2 | Posted in CT Serious
              Injury News
            </p>
            <p className="mb-8">
              Xavier Myers age 18 was pronounced dead Monday morning following a
              crash resulting from a police chase from Hartford to Bloomfield.
              Police believed there was a gun in the car and attempted to stop
              the car driven by Myers. Myers failed to stop which initiated the
              chase. A sixteen-year-old passenger with Myers suffered minor
              injuries.
            </p>
          </div>
          <div className="mb-16">
            <h2>NORWICH CT MOTORCYCLE RIDER DIES AFTER CRASH</h2>
            <p className="mb-8">
              By Frank Bartlett | Published August 1 | Posted in CT Serious
              Injury News
            </p>
            <p className="mb-8">
              Andre J. Ross of Norwich was fatally injured in an accident at 2
              A.M. Saturday morning. No other vehicles were involved. If anyone
              has information about the accident please call Norwich Officer
              Andre Rosedal at the Norwich Police Department 860-886-5561. If
              you or a loved one has been injured or died in an accident.
            </p>
          </div>
          <div className="mb-16">
            <h2>BICYCLE ACCIDENT</h2>
            <p className="mb-8">By Frank Bartlett | Published February 25</p>
            <p className="mb-8">
              Q: From Bristol, CT. Do I have a case for a bicycle accident
              trying to avoid getting hit by a car? I was riding down a hill on
              the sidewalk and saw a car encroach into a driveway on the same
              sidewalk. I attempted to stop but notice that the car stopped.
            </p>
          </div>
          <div className="mb-16">
            <h2>Q: CAN ANY LEGAL ACTION BE TAKEN?</h2>
            <p className="mb-8">
              By Frank Bartlett | Published January 28 | Posted in Personal
              Injury Law Blog
            </p>
            <p className="mb-8">
              Q: From New London, CT. There is not a police report and now I am
              experiencing pain. Can any legal action be taken? I did not think
              I was hurt at the time but now I am experiencing pain and have
              gone to the ER to no avail. The accident occurred on the school
              campus.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
